























































import { Component, Prop, Vue } from 'vue-property-decorator';
import { CommonGetters } from '@/store/modules/common/enums';

@Component
export default class CustomerPrivacyBoxs extends Vue {
  @Prop() showBox!: number;

  public get language(): string {
    return this.$store.getters[CommonGetters.Language];
  }
}
