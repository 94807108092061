






































































import { Component, Vue, Prop } from 'vue-property-decorator';
import CustomerInfo from '@/store/modules/auth/authState';
import { customerService } from '@/services/customers/customer.service';

@Component({
  components: {},
})
export default class CustomerTabManageAccount extends Vue {
  @Prop() customerInfo!: CustomerInfo;

  //TODO: use enum!
  protected switchOption = 1;

  public async changePassword(): Promise<void> {
    await this.$msal.changePassword();
  }

  public deleteProfile(): void {
    this.$spinner.showSpinner();
    setTimeout(() => void 0, 1000);
    this.$bvModal.hide('modalDeleteAccount');
    customerService
      .deleteProfile()
      .then(() => {
        this.$toaster.showSuccess('save-success', true);
        this.$emit('logout');
      })
      .catch(() => {
        this.$spinner.removeSpinner();
        this.$toaster.showError('save-error', true);
      });
  }
}
