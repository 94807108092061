



















































































































































































































import { AuthGetters } from '@/store/modules/auth/enums';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { DashboardActions } from '@/store/enums';
import { Media } from '@/models/medias/media';
import { MediaCreditType } from '@/models/medias/mediaCreditType';
import { MediasGetters } from '@/store/modules/medias/enums';
import { MerchantConfig } from '@/models/config/merchantConfig';
import { MerchantConfigGetters } from '@/store/modules/merchant-config/enums';
import { NexiPayload } from '@/models/web-payments/nexiPayload';
import { WebPaymentService } from '@/models/web-payments/webPaymentService';
import { WebPaymentConfig } from '@/models/web-payments/webPaymentConfig';
import { WebPaymentServiceType } from '@/models/web-payments/webPaymentServiceType';
import { WebPaymentOrder } from '@/models/web-payments/webPaymentOrder';
import { WebPaymentOrderType } from '@/models/web-payments/webPaymentOrderType';
import {
  WebPaymentsActions,
  WebPaymentsGetters,
} from '@/store/modules/web-payments/enums';
import CustomerInfo from '@/store/modules/auth/authState';
import NexiPurchaseForm from '@/views/customer/components/recharge-services/NexiPurchaseForm.vue';
import { isNullOrEmpty } from '@glitchedmob/isnullorempty';
import { webPaymentService } from '@/services/web-payments/webpayment.service';

@Component({
  components: {
    'ck-nexi-purchase-form': NexiPurchaseForm,
  },
})
export default class CustomerRecharge extends Vue {
  private selectedService!: WebPaymentService;
  private selectedCharge!: string;
  private createNewWebOrder!: boolean;
  private rechargeStep = 0;
  private otpValue: number | null = null;
  private purchasing = false;
  private checkoutAmount = 0;

  @Prop() loadingMedias!: boolean;
  @Prop() deviceId!: string;

  @Watch('selectedMediaId')
  changedMediaWatcher(): void {
    if (this.mediaHasCloudCredit()) {
      this.rechargeStep = 0;
      switch (this.selectedServiceType) {
        case WebPaymentServiceType.Nexi:
          this.createNewWebOrder =
            this.selectedMediaId != this.nexiPayload.media_id;
          break;
        default:
          this.createNewWebOrder = true;
          break;
      }
    } else {
      this.$emit('isBack', DashboardActions.DashboardView);
    }
  }

  created(): void {
    this.createNewWebOrder = this.selectedMediaId != this.nexiPayload.media_id;
  }

  get customerInfo(): CustomerInfo {
    return this.$store.getters[AuthGetters.GetCustomerInfo];
  }

  get hasMerchant(): boolean {
    return this.$store.getters[MediasGetters.GetMerchantId];
  }

  get medias(): Media[] {
    return this.$store.getters[MediasGetters.GetMedias];
  }

  get merchantId(): string {
    return this.$store.getters[MediasGetters.GetMerchantId];
  }

  get merchantConfig(): MerchantConfig {
    return this.$store.getters[MerchantConfigGetters.GetMerchantConfig];
  }

  get nexiPayload(): NexiPayload {
    return this.$store.getters[WebPaymentsGetters.GetNexiPayload];
  }

  get nexiTries(): number {
    return this.$store.getters[WebPaymentsGetters.GetNexiTries];
  }

  get selectedMediaId(): string {
    return this.$store.getters[MediasGetters.GetSelectedMediaId];
  }

  get selectedServiceType(): WebPaymentServiceType {
    return this.$store.getters[WebPaymentsGetters.GetSelectedServiceType];
  }

  get webPaymentServices(): WebPaymentService[] {
    return this.$store.getters[WebPaymentsGetters.GetServices];
  }

  get webPaymentConfigs(): WebPaymentConfig[] {
    return this.$store.getters[WebPaymentsGetters.GetConfigs];
  }

  get webPaymentMerchantServices(): WebPaymentService[] {
    return this.webPaymentServices.filter((wps) => {
      return (
        this.webPaymentConfigs.findIndex(
          (wpc) => wpc.webPaymentServiceId === wps.id,
        ) > -1
      );
    });
  }

  getServiceLogo(type: WebPaymentServiceType): string {
    switch (type) {
      case WebPaymentServiceType.Nexi:
        return 'nexi_logo.png';
      case WebPaymentServiceType.PayPal:
        return 'paypal_logo.png';
      case WebPaymentServiceType.Satispay:
        return 'satispay_logo.png';
      case WebPaymentServiceType.Assiopay:
        return 'assiopay_logo.png';
    }
  }

  serviceLogoWidth(type: WebPaymentServiceType): string {
    switch (type) {
      case WebPaymentServiceType.Nexi:
        return '250px';
      case WebPaymentServiceType.PayPal:
        return '160px';
      case WebPaymentServiceType.Satispay:
        return '170px';
      case WebPaymentServiceType.Assiopay:
        return '250px';
    }
  }

  selectService(service: WebPaymentService): void {
    this.otpValue = null;
    this.selectedService = service;
    this.rechargeStep = 1;
    this.$store.dispatch(
      WebPaymentsActions.SetSelectedServiceType,
      this.selectedService.webPaymentType,
    );
  }

  selectedServiceCharges(): string[] | undefined {
    return this.webPaymentConfigs
      .find((wpc) => wpc.webPaymentServiceId === this.selectedService.id)
      ?.charges.split(',');
  }

  convertToMerchantCurrency(value: string): string {
    let currency = this.merchantConfig.currency
      ? this.merchantConfig.currency
      : 'EUR';
    return this.$currency.parseWithCode(Number(value), currency);
  }

  selectCharge(charge: string): void {
    this.selectedCharge = charge;
    this.rechargeStep = 2;
    if (Number(charge) != this.nexiPayload.importo) {
      this.createNewWebOrder = true;
    }
    this.$store.dispatch(WebPaymentsActions.SetChargeAmount, Number(charge));
  }

  purchase(): void {
    switch (this.selectedService.webPaymentType) {
      case WebPaymentServiceType.Nexi:
        this.purchaseWithNexi();
        break;
      case WebPaymentServiceType.Assiopay:
        this.purchaseWithAssiopay();
        break;
      default: {
        this.$toaster.showError('webpayment.not-yet-supported', true);
        break;
      }
    }
  }

  purchaseWithNexi(): void {
    if (this.nexiTries >= 3) {
      this.createNewWebOrder = true;
      this.$store.dispatch(WebPaymentsActions.ResetNexiTries);
    }
    if (this.createNewWebOrder) {
      webPaymentService
        .createOrder(this.createWebOrder())
        .then((response) => {
          this.buildNexiPayload(response.requestDetail).then(() => {
            const nexiForm = document.getElementById(
              'nexiForm',
            ) as HTMLFormElement;
            nexiForm.submit();
          });
        })
        .catch((error) => {
          this.$toaster.showError('webpayment.order-error', true);
        });
    } else {
      const nexiForm = document.getElementById('nexiForm') as HTMLFormElement;
      nexiForm.submit();
    }
    this.createNewWebOrder = false;
    this.$store.dispatch(WebPaymentsActions.IncrementNexiTries);
  }

  async purchaseWithAssiopay(): Promise<void> {
    this.purchasing = true;
    await webPaymentService
      .createOrder(this.createWebOrder(false, true))
      .then((response) => {
        this.checkoutAmount = response.amount;
        if (this.checkoutAmount > 0) {
          (this.$refs['checkout-modal'] as any).show();
        } else {
          this.setBack();
          this.$toaster.showError('webpayment.order-not-completed', true);
        }
      })
      .catch((error) => {
        this.$toaster.showError('webpayment.order-error', true);
      })
      .finally(() => {
        this.purchasing = false;
      });
  }

  createWebOrder(withAmount = true, withOtp = false): WebPaymentOrder {
    let webPaymentOrder = new WebPaymentOrder();
    webPaymentOrder.merchantId = this.merchantId;
    webPaymentOrder.mediaId = this.selectedMediaId;
    webPaymentOrder.deviceId = this.deviceId;
    webPaymentOrder.webPaymentServiceId = this.selectedService?.id;
    if (withAmount) {
      webPaymentOrder.amount = Number(this.selectedCharge);
    }
    if (withOtp) {
      webPaymentOrder.serviceOtp = Number(this.otpValue);
    }
    webPaymentOrder.webOrderType = WebPaymentOrderType.Recharge;
    return webPaymentOrder;
  }

  async buildNexiPayload(requestDetail: NexiPayload): Promise<void> {
    let nexiPayload = requestDetail;
    nexiPayload.urlpost = webPaymentService.getIpnClientsApiEndpoint(
      requestDetail.urlpost,
    );
    nexiPayload.url =
      window.location.origin +
      '?checkout=true&serial=' +
      this.selectedMediaId.split('.')[1];
    nexiPayload.url_back =
      window.location.origin +
      '?action=recharge&type=2&serial=' +
      this.selectedMediaId.split('.')[1];
    nexiPayload.media_id = this.selectedMediaId;
    this.$store.dispatch(WebPaymentsActions.SetNexiPayload, nexiPayload);
  }

  noOp(): void {
    return;
  }

  setBack(): void {
    this.otpValue = null;
    if (this.rechargeStep == 0) {
      this.$emit('isBack', DashboardActions.DashboardView);
    } else {
      this.rechargeStep--;
    }
  }

  mediaHasCloudCredit(): boolean {
    let selectedMedia = this.medias.find((m) => m.id === this.selectedMediaId);
    if (selectedMedia) {
      return selectedMedia.creditType === MediaCreditType.CloudCredit;
    }
    return false;
  }

  isAssiopaySelected(): boolean {
    return (
      this.selectedService?.webPaymentType === WebPaymentServiceType.Assiopay
    );
  }

  isValidOtp(): boolean {
    if (this.otpValue) {
      const hasOnlyDigits = /^\d+$/.test(this.otpValue.toString());
      const otpLength = this.otpValue.toString().length;
      return hasOnlyDigits && otpLength >= 1 && otpLength <= 15;
    }
    return false;
  }

  goToAssiopayAppMsg(): string {
    const serviceCharges = this.selectedServiceCharges() ?? [];
    const lowerBound = this.convertToMerchantCurrency(serviceCharges[0]);
    const upperBound = this.convertToMerchantCurrency(serviceCharges[1]);
    return (
      this.$t('webpayment.assiopay.continue-in-app', {
        service: this.selectedService.name,
      }) +
      ' (' +
      this.$t('webpayment.assiopay.select-in-range', {
        min: lowerBound,
        max: upperBound,
      }) +
      ')'
    );
  }

  closeCheckoutSuccessModal(): void {
    (this.$refs['checkout-modal'] as any).hide();
    this.$emit('isBack', DashboardActions.DashboardView);
  }

  mediaName(media: Media): string {
    if (!isNullOrEmpty(media.alias?.trim)) {
      return media.alias + ' (' + media.serial + ')';
    }
    return media.serial ?? '';
  }

  selectedMediaName(): string {
    const selectedMedia = this.medias.find(
      (m) => m.id === this.selectedMediaId,
    );
    return selectedMedia ? this.mediaName(selectedMedia) : '';
  }
}
