















import { Component, Prop, Vue } from 'vue-property-decorator';
import i18n from '@/i18n';
import { Country } from '@/models/countries/country-state';
import { countryStateService } from '@/services/common/country-state.service';

@Component({})
export default class CountrySelector extends Vue {
  @Prop({ default: '' })
  public initial!: string;

  public selected: string | null = null;

  public options: object[] = [];

  public mounted(): void {
    this.getItems();
    if (this.initial) this.selected = this.initial;
  }

  public get getCurrentLanguage(): string {
    return i18n.locale ?? 'en';
  }

  private getItems(): void {
    countryStateService
      .getCountries(this.getCurrentLanguage)
      .then((countries: any) => {
        const sortedByName = countries.data.slice(0) as Country[];
        sortedByName.sort((a, b) => {
          const x = a.name.toLowerCase();
          const y = b.name.toLowerCase();
          return x.localeCompare(y);
        });

        this.options = sortedByName.map((c) => {
          return {
            value: c.shortCode,
            text: c.name,
          };
        });
      });
  }

  public selectCountry(value: string): void {
    this.$emit('change', value);
  }
}
