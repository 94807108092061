







































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { DashboardActions } from '@/store/enums';
import { MediasGetters } from '@/store/modules/medias/enums';
import CustomerInfo from '@/store/modules/auth/authState';
import CustomerTabManageAccount from '@/views/customer/components/CustomerTabManageAccount.vue';
import CustomerTabPersonalData from '@/views/customer/components/CustomerTabPersonalData.vue';
import CustomerTabPrivacyConsents from '@/views/customer/components/CustomerTabPrivacyConsents.vue';

@Component({
  components: {
    'ck-customer-tab-manage-account': CustomerTabManageAccount,
    'ck-customer-tab-personal-data': CustomerTabPersonalData,
    'ck-customer-tab-privacy-consents': CustomerTabPrivacyConsents,
  },
})
export default class CustomerAccount extends Vue {
  @Prop() customerInfo!: CustomerInfo;

  protected switchOption = 0;

  get hasMerchant(): boolean {
    return this.$store.getters[MediasGetters.GetMerchantId];
  }

  setBack(): void {
    this.$emit('isBack', DashboardActions.DashboardView);
  }

  toggleMenu(_switch: number): void {
    this.switchOption = _switch;
  }

  logout(): void {
    this.$emit('logout');
  }
}
