import { Country, State } from '@/models/countries/country-state';
import BaseService from '@/services/base/base.service';
import axios from 'axios';

export class CountryStateService extends BaseService {
  async getCountries(language: string): Promise<Country[]> {
    return await axios.get(
      `${super.clientsApiBaseUrl()}/countries/?lang=${language}`,
    );
  }

  async getStates(country: string): Promise<State[]> {
    return await axios.get(
      `${super.clientsApiBaseUrl()}/countries/${country}/states`,
    );
  }
}

export const countryStateService = new CountryStateService();
