






















































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { PrivacyConsents } from '@/models/customers/privacyConsents';
import { MediasGetters } from '@/store/modules/medias/enums';
import CustomerPrivacyBoxs from '@/views/customer/components/CustomerPrivacyBoxs.vue';
import { customerService } from '@/services/customers/customer.service';

@Component({
  components: {
    CustomerPrivacyBoxs,
  },
})
export default class CustomerTabPrivacyConsents extends Vue {
  protected loaded = false;
  public privacyInfo: PrivacyConsents = new PrivacyConsents();

  public get merchantId(): string {
    return this.$store.getters[MediasGetters.GetMerchantId];
  }

  @Watch('merchantId')
  public async loadMerchantConsents(): Promise<void> {
    this.loaded = false;
    customerService
      .getPrivacyConsents(this.merchantId)
      .then((response: PrivacyConsents) => {
        this.privacyInfo = response;
        this.loaded = true;
      });
  }

  async created(): Promise<void> {
    if (!this.loaded) this.loadMerchantConsents();
  }

  savePrivacyConsents(): void {
    customerService
      .updatePrivacyConsents(this.merchantId, this.privacyInfo)
      .then(() => this.$toaster.showSuccess('save-success', true))
      .catch(() => this.$toaster.showError('save-error', true));
  }
}
