var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-sm-12 col-lg-8 lp-content"},[_c('ul',{staticClass:"nav nav-tabs nav-fill"},[_c('li',{staticClass:"nav-item",attrs:{"role":"presentation"}},[_c('a',{class:[
          'nav-link',
          _vm.switchOption == 0 ? 'active' : 'cursor-pointer',
          'text-capitalize font-weight-bold' ],attrs:{"aria-controls":"personal-information","data-toggle":"tab"},on:{"click":function($event){return _vm.toggleMenu(0)}}},[_vm._v(" "+_vm._s(_vm.$t('account.account'))+" ")])]),_c('li',{staticClass:"nav-item",attrs:{"role":"presentation"}},[_c('a',{class:[
          'nav-link',
          _vm.switchOption == 1 ? 'active' : 'cursor-pointer',
          'text-capitalize font-weight-bold' ],attrs:{"aria-controls":"personal-information","data-toggle":"tab"},on:{"click":function($event){return _vm.toggleMenu(1)}}},[_vm._v(" "+_vm._s(_vm.$t('account.personal-information'))+" ")])]),(_vm.hasMerchant)?_c('li',{staticClass:"nav-item",attrs:{"role":"presentation"}},[_c('a',{class:[
          'nav-link',
          _vm.switchOption == 2 ? 'active' : 'cursor-pointer',
          'text-capitalize font-weight-bold' ],attrs:{"aria-controls":"privacy","data-toggle":"tab"},on:{"click":function($event){return _vm.toggleMenu(2)}}},[_vm._v(" "+_vm._s(_vm.$t('account.privacy'))+" ")])]):_vm._e()]),_c('hr',{staticClass:"styled d-md-none"}),_c('div',{staticClass:"tab-content mt-4"},[(_vm.switchOption == 0)?_c('div',{class:['tab-pane', 'fade', 'show active'],attrs:{"id":"account","role":"tabpanel"}},[_c('ck-customer-tab-manage-account',{attrs:{"customer-info":_vm.customerInfo},on:{"logout":_vm.logout}})],1):_vm._e(),(_vm.switchOption == 1)?_c('div',{class:['tab-pane', 'fade', 'show active'],attrs:{"id":"personal-information","role":"tabpanel"}},[_c('ck-customer-tab-personal-data',{attrs:{"customer-info":_vm.customerInfo}})],1):_vm._e(),(_vm.switchOption == 2)?_c('div',{class:['tab-pane', 'fade', 'show active'],attrs:{"id":"privacy","role":"tabpanel"}},[_c('ck-customer-tab-privacy-consents')],1):_vm._e()]),_c('div',{staticClass:"form-group row"},[_c('div',{staticClass:"col-12 text-left"},[_c('button',{staticClass:"\n          btn btn-primary btn-md\n          bg-dark\n          text-capitalize\n          border-dark\n          font-weight-bold\n        ",attrs:{"type":"button"},on:{"click":function($event){return _vm.setBack()}}},[_vm._v(" "+_vm._s(_vm.$t('back'))+" ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }