





































































































































































































































































































































































































































































































import { AuthGetters } from '@/store/modules/auth/enums';
import { CommonGetters } from '@/store/modules/common/enums';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { DashboardActions } from '@/store/enums';
import { Media } from '@/models/medias/media';
import { MediaCreditType } from '@/models/medias/mediaCreditType';
import { MediasActions, MediasGetters } from '@/store/modules/medias/enums';
import { MerchantConfig } from '@/models/config/merchantConfig';
import {
  MerchantConfigActions,
  MerchantConfigGetters,
} from '@/store/modules/merchant-config/enums';
import {
  MerchantMigrationActions,
  MerchantMigrationGetters,
} from '@/store/modules/merchant-migration/enums';
import {
  WebPaymentsActions,
  WebPaymentsGetters,
} from '@/store/modules/web-payments/enums';
import BlogPostsContainer from '@/views/blog/BlogPostsContainer.vue';
import BlogPostsLatest from '@/views/blog/BlogPostsLatest.vue';
import CustomerAccount from '@/views/customer/components/CustomerAccount.vue';
import CustomerInfo from '@/store/modules/auth/authState';
import CustomerPurchase from '@/views/customer/components/CustomerPurchase.vue';
import CustomerRecharge from '@/views/customer/components/CustomerRecharge.vue';
import CustomerServices from '@/views/customer/components/CustomerServices.vue';
import MediaAliasEditor from '@/components/dashboard/MediaAliasEditor.vue';
import { mediaService } from '@/services/medias/media.service';
import { isNullOrEmpty } from '@glitchedmob/isnullorempty';
import { getContrastText } from '@/utils/colors.utils';
import { fixMerchantColor } from '@/utils/colors.utils';

import aziCodesMappingsJson from '@/assets/migration/aziCodeMappings.json';

@Component({
  components: {
    'ck-blog-posts-container': BlogPostsContainer,
    'ck-blog-posts-latest': BlogPostsLatest,
    CustomerAccount,
    CustomerPurchase,
    'ck-customer-recharge': CustomerRecharge,
    CustomerServices,
    'ck-media-alias-editor': MediaAliasEditor,
  },
})
export default class CustomerDashboard extends Vue {
  @Prop() showAccount!: boolean;
  @Prop() mediaSerial!: string;
  @Prop() deviceId!: string;
  @Prop() action!: DashboardActions;
  @Prop() showCheckoutSuccess!: boolean;

  aziCodesJson = aziCodesMappingsJson;

  protected loadingMedias = false;

  public selectedMedia = 0;

  public dashboardAction: DashboardActions = DashboardActions.DashboardView;

  DashboardActions = DashboardActions;

  get computedColor(): string {
    return fixMerchantColor(this.merchantConfig?.color);
  }

  created(): void {
    this.showAccountWatcher();
    this.dashboardAction = this.action;

    if (this.mediaSerial) {
      const mediaIndex = this.medias.findIndex(
        (m) => m.serial === this.mediaSerial,
      );
      if (mediaIndex == -1) {
        this.$toaster.showError('media-not-registered', true);
        return;
      }
      this.selectedMedia = mediaIndex;
    }
    if (this.medias.length > 0) {
      this.$store.dispatch(MediasActions.SetSelectedMediaId, this.getMedia.id);
    }
  }

  mounted(): void {
    if (this.showCheckoutSuccess) {
      (this.$refs['checkout-modal'] as any).show();
    }
  }

  @Watch('showAccount')
  public showAccountWatcher(): void {
    if (this.showAccount)
      this.setDashboardAction(DashboardActions.EditAccount, false);
  }

  @Watch('selectedMedia')
  public changeMediaWatcher(): void {
    this.$store
      .dispatch(MediasActions.SetMerchantId, this.getMedia.merchantId)
      .then(() =>
        this.$store.dispatch(
          MerchantConfigActions.GetMerchantConfig,
          this.getMedia.merchantId,
        ),
      );
    this.$store.dispatch(
      WebPaymentsActions.GetConfigs,
      this.getMedia.merchantId,
    );
    this.$store.dispatch(
      MerchantMigrationActions.GetMigratedMerchants,
      this.getMedia.merchantId,
    );
    this.$store.dispatch(MediasActions.SetSelectedMediaId, this.getMedia.id);
    if (this.action !== DashboardActions.OnlineRecharge) {
      this.dashboardAction = DashboardActions.DashboardView;
    }
  }

  get checkoutAmount(): number {
    return this.$store.getters[WebPaymentsGetters.GetChargeAmount];
  }

  get customerInfo(): CustomerInfo {
    return this.$store.getters[AuthGetters.GetCustomerInfo];
  }

  get emptyLastUsedDate(): boolean {
    const date = this.getMedia?.lastUsedDate ?? undefined;
    return date == null || date.toString() === '0001-01-01T00:00:00';
  }

  get hasMerchant(): boolean {
    return this.$store.getters[MediasGetters.GetMerchantId];
  }

  get medias(): Media[] {
    return this.$store.getters[MediasGetters.GetMedias];
  }

  get merchantConfig(): MerchantConfig {
    return this.$store.getters[MerchantConfigGetters.GetMerchantConfig];
  }

  get migratedMerchants(): string[] {
    return this.$store.getters[MerchantMigrationGetters.GetMigratedMerchants];
  }

  public get language(): string {
    return this.$store.getters[CommonGetters.Language];
  }

  public get getMedia(): Media {
    return this.medias.length > 0
      ? this.medias[this.selectedMedia]
      : ({} as Media);
  }

  setDashboardAction(action: DashboardActions, isBack: boolean): void {
    this.dashboardAction = action;
    if (isBack) this.$emit('isBack');
  }

  handleDeleteKey(): void {
    this.$spinner.showSpinner();
    this.loadingMedias = true;
    this.$bvModal.hide('modalDeleteKey');
    mediaService
      .deleteMediaCustomer(this.getMedia.id as string)
      .then(() => {
        this.$toaster.showSuccess('save-success', true);
        this.$store.dispatch(MediasActions.GetMedias, true).then(() => {
          this.loadingMedias = false;
          if (this.medias.length > 0) {
            this.selectedMedia = 0;
            this.changeMediaWatcher();
          } else {
            this.$store.dispatch(MerchantConfigActions.Clear);
          }
        });
      })
      .catch(() => {
        this.$toaster.showError('save-error', true);
      })
      .finally(() => this.$spinner.removeSpinner());
  }

  logout(): void {
    this.$emit('logout');
  }

  public creditTypeIcon(): string {
    if (this.getMedia.creditType === MediaCreditType.CloudCredit) {
      return 'fa-cloud';
    }
    return 'fa-key';
  }

  public creditTypeTitle(): string {
    if (this.getMedia.creditType === MediaCreditType.CloudCredit) {
      return this.$t('credit-type.cloud').toString();
    }
    return this.$t('credit-type.key').toString();
  }

  public mediaName(media: Media): string {
    if (!isNullOrEmpty(media.alias?.trim)) {
      return media.alias + ' (' + media.serial + ')';
    }
    return media.serial ?? '';
  }

  public contrastColor(color: string): string {
    return getContrastText(color);
  }

  closeCheckoutSuccessModal(): void {
    (this.$refs['checkout-modal'] as any).hide();
    this.$emit('close-checkout-modal');
  }

  refreshMedias(): void {
    this.$emit('refresh-medias');
  }

  // merchantFullyMigrated(): boolean {
  //   const merchantId = this.$store.getters[MediasGetters.GetMerchantId];
  //   const merchantMigrationMapping = this.aziCodesJson.mappings.find(
  //     (mapping) => {
  //       return mapping.merchantId === merchantId;
  //     },
  //   );

  //   if (merchantMigrationMapping && merchantMigrationMapping.aziCodes) {
  //     const aziCodesCount = merchantMigrationMapping.aziCodes.length;
  //     const migratedMerchantsCount = this.migratedMerchants.length;
  //     if (aziCodesCount > 0) {
  //       if (migratedMerchantsCount < aziCodesCount) {
  //         return false;
  //       } else {
  //         let fullyMigrated = true;

  //         const migratedAziCodes = this.migratedMerchants.map(
  //           (m) => m.split('|')[0],
  //         );

  //         for (let i = 0; i < merchantMigrationMapping.aziCodes.length; i++) {
  //           const aziCode = merchantMigrationMapping.aziCodes[i];
  //           fullyMigrated &&= migratedAziCodes.toString().includes(aziCode);
  //         }

  //         return fullyMigrated;
  //       }
  //     }
  //   }
  //   return true;
  // }

  protected landingPageEnabled(): boolean {
    return (
      this.$store.getters[MerchantConfigGetters.GetMerchantConfig]?.services
        ?.length > 0
    );
  }
}
