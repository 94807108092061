



















































import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import { Media } from '@/models/medias/media';
import { MediasActions } from '@/store/modules/medias/enums';
import { isNotNullOrWhiteSpace } from '@glitchedmob/isnullorempty';
import { mediaService } from '@/services/medias/media.service';

@Component
export default class MediaAliasEditor extends Vue {
  @Prop() media!: Media;
  @Prop() contrastColor!: string;

  public showSaveButton = false;
  public saving = false;

  @Ref('mediaAliasNode') mediaAliasNode!: HTMLInputElement;

  @Watch('showSaveButton', { immediate: true })
  public editableWatcher(): void {
    if (this.showSaveButton) {
      this.$nextTick(() => this.mediaAliasNode.focus());
      this.focusName();
    }
  }

  get getAlias(): string {
    return isNotNullOrWhiteSpace(this.media.alias)
      ? this.media.alias
      : this.media.serial ?? '';
  }

  public async updateName(): Promise<void> {
    this.showSaveButton = false;
    const element = this.$refs.mediaAliasNode as Element;
    const newAlias = element.innerHTML.trim().replace(/&nbsp;/g, '');
    const mediaSerial = this.media.serial;
    if (this.media.id && this.media.merchantId) {
      this.saving = true;
      await mediaService
        .updateMedia(this.media.id, this.media.merchantId, newAlias)
        .then(() => {
          this.media.alias = newAlias;
          this.$store.dispatch(MediasActions.UpdateAlias, {
            alias: newAlias,
            mediaSerial: mediaSerial,
          });
          this.$toaster.showSuccess('save-success', true);
        })
        .catch(() => {
          this.$toaster.showError('save-error', true);
          element.innerHTML = this.getAlias;
        })
        .finally(() => {
          this.saving = false;
        });
    }
  }

  public focusName(): void {
    const range = document.createRange();
    range.selectNodeContents(this.mediaAliasNode);
    let sel = window.getSelection();
    if (sel != null) {
      sel.removeAllRanges();
      sel.addRange(range);
    }
  }
}
