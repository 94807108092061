export class PrivacyConsents {
  public id = '';
  public merchantId = '';
  public privacy1: boolean | undefined;
  public privacy: boolean | undefined;
  public privacy1ConsentDate = '';
  public privacy2: boolean | undefined;
  public privacy2ConsentDate = '';
  public privacy3: boolean | undefined;
  public privacy3ConsentDate = '';
}
