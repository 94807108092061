












































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { State } from '@/models/countries/country-state';
import CountrySelector from '@/components/common/CountrySelector.vue';
import CustomerInfo from '@/store/modules/auth/authState';
import StateSelector from '@/components/common/StateSelector.vue';
import { countryStateService } from '@/services/common/country-state.service';
import { customerService } from '@/services/customers/customer.service';
import { isNullOrWhiteSpace } from '@glitchedmob/isnullorempty';
import i18n from '@/i18n';

@Component({
  components: {
    'ck-country-selector': CountrySelector,
    'ck-state-selector': StateSelector,
  },
})
export default class CustomerTabPersonalData extends Vue {
  @Prop() customerInfo!: CustomerInfo;

  protected name = '';
  protected surname = '';
  protected streetAddress = '';
  protected state = '';
  protected city = '';
  protected postalCode = '';
  protected states: State[] = [];

  public async mounted(): Promise<void> {
    this.name = this.customerInfo.name;
    this.surname = this.customerInfo.surname;
    this.streetAddress = this.customerInfo.streetAddress;
    this.city = this.customerInfo.city;
    this.postalCode = this.customerInfo.postalCode;
    await this.getStates();
  }

  private async getStates(): Promise<void> {
    if (this.customerInfo?.country) {
      await countryStateService
        .getStates(this.customerInfo.country)
        .then((s: any) => {
          this.states = s.data;
        });
    }
  }

  protected requiredState(test: string): boolean | null {
    if (this.maxLengthRule(test, 100))
      return test.replace(/\s+/g, '') ? null : false;
    return false;
  }

  private maxLengthRule(test: string, maxLength: number): boolean {
    if (isNullOrWhiteSpace(test)) return true;
    return test.trim().length <= maxLength;
  }

  private exactLengthRule(test: string, length: number): boolean {
    if (isNullOrWhiteSpace(test)) return false;
    return test.trim().length == length;
  }

  protected maxLengthState(test: string, maxLength: number): boolean | null {
    return this.maxLengthRule(test, maxLength) ? null : false;
  }

  protected validForm(): boolean {
    let valid = true;
    valid &&= this.requiredState(this.name) == null;
    valid &&= this.requiredState(this.surname) == null;
    if (this.customerInfo.gender)
      valid &&= new RegExp('^(M|F|m|f)$').test(this.customerInfo.gender);
    valid &&= this.maxLengthRule(this.streetAddress, 255);
    valid &&= this.maxLengthRule(this.customerInfo.country, 100);
    valid &&= this.maxLengthRule(this.customerInfo.state, 100);
    valid &&= this.maxLengthRule(this.city, 100);
    valid &&= this.maxLengthRule(this.postalCode, 10);
    valid &&= this.exactLengthRule(this.getCurrentLanguage, 2);
    return valid;
  }

  protected switchOption = true;

  async saveProfile(): Promise<void> {
    if (this.validForm()) {
      this.$spinner.showSpinner();
      let profile = this.customerInfo;
      profile.name = this.name?.trim();
      profile.surname = this.surname?.trim();
      profile.streetAddress = this.streetAddress?.trim();
      profile.city = this.city?.trim();
      profile.postalCode = this.postalCode?.trim();
      profile.language = this.getCurrentLanguage;
      await customerService
        .updateProfile(profile)
        .then(() => this.$toaster.showSuccess('save-success', true))
        .catch(() => this.$toaster.showError('save-error', true))
        .finally(() => this.$spinner.removeSpinner());
    } else {
      this.$toaster.showError('save-error', true);
    }
  }

  public get getCurrentLanguage(): string {
    return i18n.locale ?? 'en';
  }

  changeCountry(country: string): void {
    this.customerInfo.country = country;
    this.getStates();
    this.customerInfo.state = '';
  }

  changeState(state: string): void {
    this.customerInfo.state = state;
  }
}
