















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { State } from '@/models/countries/country-state';

@Component({})
export default class StateSelector extends Vue {
  @Prop()
  public country!: string;
  @Prop()
  public initial!: string;
  @Prop()
  public states!: State[];

  @Watch('country')
  public refreshStates(): void {
    this.selected = null;
  }

  public selected: string | null = null;

  public options: State[] = [];

  public mounted(): void {
    if (!this.country) {
      return;
    }
    if (this.initial) this.selected = this.initial;
  }

  public selectState(value: string): void {
    this.$emit('change', value);
  }
}
