





















































import { Component, Prop, Vue } from 'vue-property-decorator';
import { DashboardActions } from '@/store/enums';
import { Media } from '@/models/medias/media';
import { MediaCreditType } from '@/models/medias/mediaCreditType';

@Component
export default class CustomerServices extends Vue {
  @Prop() services!: any[];
  @Prop() media!: Media;
  @Prop() merchantColor!: string;

  private readonly rechargeLink = DashboardActions.OnlineRecharge;
  private readonly purchaseLink = DashboardActions.PurchaseHistory;
  private readonly feedbackLink = DashboardActions.Feedback;

  public getDisabledCss(service: string): string {
    return !this.isServiceEnabled(service) ? 'disabled' : '';
  }

  isServiceEnabled(service: string): boolean {
    return this.services.find((s) => s == service) as boolean;
  }

  emitEvent(action: DashboardActions, isAvailable: boolean): void {
    if (isAvailable) {
      this.$emit('onServiceSelected', action);
    }
  }

  mediaHasCloudCredit(): boolean {
    return this.media.creditType === MediaCreditType.CloudCredit;
  }
}
