


































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { CommonGetters } from '@/store/modules/common/enums';
import { MediasGetters } from '@/store/modules/medias/enums';
import { customerService } from '@/services/customers/customer.service';
import { MerchantConfig } from '@/models/config/merchantConfig';
import { MerchantConfigGetters } from '@/store/modules/merchant-config/enums';
import { DashboardActions } from '@/store/enums';

@Component
export default class CustomerPurchase extends Vue {
  @Prop() loadingMedias!: boolean;
  @Prop() mediaId!: string;

  public purchases = [];
  public page = 1;
  public totalItems = 200;
  public size = 10;

  @Watch('mediaId', { immediate: true })
  async onMatchChanged(): Promise<void> {
    await this.setData();
  }

  @Watch('page')
  async changePage(): Promise<void> {
    await this.setData();
  }

  get merchantConfig(): MerchantConfig {
    return this.$store.getters[MerchantConfigGetters.GetMerchantConfig];
  }

  public get language(): string {
    return this.$store.getters[CommonGetters.Language];
  }

  async setData(): Promise<void> {
    this.$spinner.showSpinner();
    let merchantId = this.$store.getters[MediasGetters.GetMerchantId];

    customerService
      .loadUserPurchases(this.mediaId, merchantId, this.page, this.size)
      .then((response) => {
        this.purchases = response.data;
        this.readHeaders(response.headers);
      })
      .finally(() => {
        this.$spinner.removeSpinner();
      });
  }

  getFormattedDate(date: string): string {
    return new Date(date).toLocaleString(this.language);
  }

  getValue(purchase: any): string {
    let valueToParse = purchase.value;
    if (
      purchase.transactionType === 4 ||
      purchase.transactionType === 12 ||
      purchase.transactionType === 16 ||
      purchase.transactionType === 17
    ) {
      valueToParse = purchase.valueCharge;
    }
    return this.$currency.parseWithCode(
      valueToParse,
      this.merchantConfig.currency as string,
    );
  }

  setBack(): void {
    this.$emit('isBack', DashboardActions.DashboardView);
  }

  public readHeaders(headers: any): void {
    if (
      'x-total-count' in headers &&
      typeof headers['x-total-count'] !== 'undefined'
    ) {
      this.totalItems = Number(headers['x-total-count']);
    }
  }
}
