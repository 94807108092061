


























import { Component, Prop, Vue } from 'vue-property-decorator';
import { MediasGetters } from '@/store/modules/medias/enums';
import { NexiPayload } from '@/models/web-payments/nexiPayload';
import { WebPaymentsGetters } from '@/store/modules/web-payments/enums';

@Component
export default class NexiPurchaseForm extends Vue {
  protected static readonly TIPO_SERVIZIO = 'paga_1click';

  @Prop() serviceUrl!: string;

  get nexiPayload(): NexiPayload {
    return this.$store.getters[WebPaymentsGetters.GetNexiPayload];
  }

  get selectedMediaId(): string {
    return this.$store.getters[MediasGetters.GetSelectedMediaId];
  }

  get tipoServizio(): string {
    return NexiPurchaseForm.TIPO_SERVIZIO;
  }
}
